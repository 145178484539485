<template>
  <div>
    <heads></heads>
    <div class="w">
      <!-- 面包屑 -->
      <div class="breadcrumb d-flex align-center">
        <div class="breadcrumb-title d-flex align-center cursor">
          <span></span>
          直播中心
          <span></span>
        </div>
        <!-- <ul class="breadcrumb-list d-flex align-center">
          <li class="breadcrumb-item cursor">幼儿教师资格</li>
          <li class="breadcrumb-item">></li>
          <li class="breadcrumb-item cursor">第一节教育</li>
        </ul> -->
      </div>
      <div class="filter">
        <div class="filter-banner">
          <img src="../../assets/img/live_banner.png" alt="" />
        </div>

        <div class="filter-list" :class="{ flag: !flag }">
          <div
            class="filter-more d-flex align-center cursor"
            @click="showTag(true)"
            v-show="!flag"
          >
            更多课程
            <img src="../../assets/img/filter_more.png" alt="" class="" />
          </div>
          <div
            class="item d-flex"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <img class="item-img" src="../../assets/img/filter_1.png" alt="" />
            <div class="item-name">{{ item.name }}：</div>
            <ul class="item-list d-flex flex-wrap">
              <li
                class="item-btn d-flex align-center cursor"
                :class="{ btn_active: item.id == btnId }"
                @click="selectBtn(item.id, 1, 0, index)"
              >
                全部
              </li>
              <li
                class="item-btn d-flex align-center cursor"
                v-for="(items, idx) in item.majorSons"
                :key="idx"
                :class="{ btn_active: items.id == btnId }"
                @click="selectBtn(items.id, 0, item.id, index)"
              >
                {{ items.name }}
              </li>
              <!-- <li class="item-btn d-flex align-center cursor">幼儿园</li>
              <li class="item-btn d-flex align-center cursor">幼儿园</li>
              <li class="item-btn d-flex align-center cursor">幼儿园</li> -->
            </ul>
          </div>
          <div class="stop d-flex align-center justify-center" v-show="flag">
            <img
              @click="showTag(false)"
              class="cursor"
              src="../../assets/img/stop.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="condition d-flex">
        <div class="condition-list d-flex align-center">
          <div
            class="condition-item cursor"
            @click="selectSort(0)"
            :class="{ condition_active: sort == 0 }"
          >
            综合
          </div>
          <div
            class="condition-item d-flex align-center cursor"
            @click="selectSort(1)"
            :class="{ condition_active: sort == 1 }"
          >
            按价格
            <div class="item-img" v-if="sort_type == 0">
              <img class="item-top" src="../../assets/img/pic_tb.png" alt="" />
              <img class="item-b" src="../../assets/img/pic_tw.png" alt="" />
            </div>
            <div class="item-img" v-if="sort_type != 0">
              <img class="item-top" src="../../assets/img/pic_tw.png" alt="" />
              <img class="item-b" src="../../assets/img/pic_tb.png" alt="" />
            </div>
          </div>
          <div
            class="condition-item cursor"
            @click="selectSort(2)"
            :class="{ condition_active: sort == 2 }"
          >
            按人气
          </div>
        </div>
      </div>
      <ul class="list" v-if="list">
        <li
          class="live-item d-flex align-center"
          v-for="(item, index) in list"
          :key="index"
        >
          <!-- <div class="time d-flex flex-column align-center">
            <div class="time-t">27日 周二</div>
            <div class="time-c">19:30-21:30</div>
            <div class="time-b d-flex align-center justify-center">
              即将开播
            </div>
          </div> -->
          <div class="detail d-flex justify-between">
            <div>
              <!-- <div class="detail-img">
                <img :src="item.icon" alt="" />
              </div> -->
              <div class="detail-name d-flex align-center">
                <p>{{ item.name }}</p>
                <img src="../../assets/img/tag_buy.png" alt="" />
                <div class="bq d-flex align-center">{{ item.majorName }}</div>
              </div>
              <div class="detail-bottom d-flex align-start">
                <div class="teacher">标签：{{ item.labelStr }}</div>
                <div class="status">已1199人预约</div>
                <!-- <div class="status-live d-flex align-center">
                  直播中
                  <img
                    class="status-img"
                    src="../../assets/img/live_z.png"
                    alt=""
                  />
                </div> -->
              </div>
            </div>
            <div class="d-flex flex-column align-center justify-betweend">
              <div class="detail-pic">￥{{ item.price }}</div>
              <div
                class="detail-btn d-flex align-center justify-center cursor"
                @click="goDetail(item.id)"
              >
                查看详情
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="!list"
        class="kong d-flex align-center flex-column justify-center"
      >
        {{ list }}
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
      <v-pagination
        v-show="total > display"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
export default {
  data() {
    return {
      flag: true,
      total: 0, // 记录总条数
      display: 7, // 每页显示条数
      current: 1, // 当前的页数
      btn: {
        backgroundImage:
          "url(" + require("../../assets/img/live_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      tabList: "", //科目选择列表
      btnId: -1, //选中的ID
      sort: 0, //筛选
      sort_type: 0, //价格筛选
      list: [], //列表
      majorId: 0,
      majorSonId: 0
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.getSort();
    this.getlist();
  },
  methods: {
    showTag(flag) {
      console.log(flag);
      this.flag = flag;
    },
    goLive() {
      location.href = "./html/live.html?a=" + "asdasd";
    },
    goDetail(id) {
      const user = JSON.parse(window.localStorage.getItem("user"));
      if (!user) {
        this.$router.push("login");
      } else {
        this.$router.push({
          name: `liveDetail`,
          query: {
            id: id,
            type:'2'
          }
        });
      }
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getlist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },

    // 科目数据列表
    async getSort(params) {
      const res = await this.$ajaxRequest('get', 'packagesSort', params)
      console.log(res.data);
      this.tabList = res.data;
    },
    // 数据列表
    async getlist(params) {
      let _this = this
      let data = {
        majorId: _this.majorId,
        majorSonId: _this.majorSonId,
        pageNum: _this.current,
        pageSize: _this.display,
        sortType: _this.sort,
        isDesc: _this.sort_type
      }
      const res = await this.$ajaxRequest('post', 'queryList', data)
      _this.list = []
      _this.total = res.data.totalRows;
      _this.list = res.data.list;
    },
    //科目选择
    selectBtn(item, status, id, index) {
      let _this = this;
      if (status == 1) {
        _this.majorId = item;
        _this.majorSonId = id;
      } else {
        _this.majorId = id;
        _this.majorSonId = item;
      }
      if (_this.btnId != item) {
        let tabList = _this.tabList;
        tabList.unshift(tabList.splice(index, 1)[0]);
        _this.tabList = tabList;
        _this.btnId = item;
        _this.current = 1;
        _this.getlist();
        _this.flag = false;
      }
    },
    //筛选
    selectSort(index) {
      let _this = this;
      // _this.sort = index;
      console.log(index);
      if (index == 1) {
        if (_this.sort_type == 0) {
          _this.sort = index;
          _this.sort_type = 1;
          _this.current = 1;
          this.getlist();
        } else {
          _this.sort = index;
          _this.sort_type = 0;
          _this.current = 1;
          this.getlist();
        }
      } else if (_this.sort != index) {
        console.log(123);
        _this.sort = index;
        _this.current = 1;
        this.getlist();
      }
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.flag {
  height: 80px;
  overflow: hidden;
  .item {
    margin-bottom: 50px;
  }
}
.btn_active {
  background: linear-gradient(176deg, #406bff 0%, #5f2eff 100%) !important;
  color: #ffffff !important;
}
.condition_active {
  color: #5f2eff !important ;
  &::after {
    content: "";
    width: 20px;
    height: 2px;
    background: #5f2eff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -10px;
  }
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span:first-child {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
    span:last-child {
      width: 1px;
      height: 10px;
      background: #cccccc;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
  .breadcrumb-item {
    font-size: 14px;
    color: #888888;
    margin-left: 12px;
  }
}
.filter {
  margin-top: 20px;
  position: relative;
  .filter-banner {
    width: 1200px;
    height: 260px;
  }
  .filter-list {
    padding: 20px 58px 0px 101px;
    background: #ffffff;
    box-shadow: 0px 0px 27px 0px rgba(222, 222, 222, 0.4);
    border: 1px solid transparent;
    position: relative;
    .stop {
      height: 52px;
      background: #ffffff;
      img {
        width: 22px;
        height: 12px;
        margin-right: 43px;
      }
    }
    .filter-more {
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 38px;
      right: 22px;
      z-index: 10;
      img {
        height: 14px;
        width: 14px;
        margin-left: 7px;
      }
    }
    .item {
      // height: 52px;
      padding-top: 16px;
      box-sizing: border-box;
      position: relative;
      .item-img {
        width: 28px;
        height: 28px;
        position: absolute;
        left: -43px;
      }
      .item-name {
        width: 174px;
        font-size: 20px;
        color: #3b454a;
      }
      .item-list {
        width: 867px;
      }
      .item-btn {
        height: 30px;
        min-width: 74px;
        background: #f4f4f4;
        border-radius: 15px;
        padding: 0 20px;
        font-size: 16px;
        color: #666666;
        box-sizing: border-box;
        margin: 0 15px;
        margin-bottom: 16px;
      }
    }
    .item + .item {
      border-top: 1px dashed #eeeeee;
    }
  }
}
.condition {
  margin-top: 50px;
  .condition-list {
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
    border-radius: 21px;
    padding: 0 26px;
    box-sizing: border-box;
    .condition-item {
      font-size: 16px;
      color: #888888;
      position: relative;

      .item-img {
        margin-left: 3px;
        .item-top {
          width: 10px;
          height: 5px;
        }
        .item-b {
          width: 10px;
          height: 5px;
          transform: rotate(180deg);
          margin-top: 3px;
        }
      }
    }
    .condition-item + .condition-item {
      margin-left: 35px;
    }
  }
}
.list {
  .live-item {
    height: 130px;
    margin-top: 40px;
    .order {
      width: 16px;
      height: 16px;
      position: relative;
      margin-left: 16px;
      img {
        position: relative;
      }
      &::before {
        content: "";
        display: block;
        height: 170px;
        width: 2px;
        background: #f4f0ff;
        position: absolute;
        top: 8px;
        left: 7px;
      }
    }

    .time {
      .time-t {
        font-size: 20px;
        color: #333333;
      }
      .time-c {
        font-size: 20px;
        color: #999999;
        margin: 10px 0;
      }
      .time-b {
        width: 80px;
        height: 22px;
        border: 1px solid #5f2eff;
        border-radius: 11px;
        font-size: 12px;
        color: #5f2eff;
      }
    }
    .detail {
      width: 100%;
      height: 130px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(228, 228, 228, 0.7);
      border-radius: 4px;
      position: relative;
      padding: 24px 30px 0 24px;
      box-sizing: border-box;
      margin-left: auto;
      .detail-img {
        width: 68px;
        height: 66px;
        border-radius: 50%;
        position: absolute;
        left: -34px;
        top: 33px;
        overflow: hidden;
        background-color: #fff;
      }
      .detail-name {
        p {
          font-size: 20px;
          color: #333333;
        }

        img {
          width: 45px;
          height: 24px;
          margin-left: 10px;
        }
        .bq {
          min-width: 69px;
          height: 24px;
          border: 1px solid #5f2eff;
          border-radius: 12px;
          font-weight: 400;
          color: #5f2eff;
          padding: 0 22px;
          box-sizing: border-box;
          margin-left: 17px;
        }
      }
      .detail-bottom {
        margin-top: 20px;
        .teacher {
          font-size: 16px;
          color: #888888;
          margin-right: 41px;
        }
        .status {
          font-size: 16px;
          color: #5f2eff;
        }
        .status-live {
          font-size: 16px;
          color: #f53444;
          margin-left: 43px;
          .status-img {
            width: 24px;
            height: 16px;
            margin-left: 14px;
          }
        }
      }
      .detail-pic {
        font-size: 30px;
        font-weight: bold;
        color: #f53444;
        height: 40px;
      }
      .live {
        width: 138px;
        height: 42px;
        background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
        border-radius: 21px;
        font-size: 16px;
        color: #ffffff;
      }
      .detail-btn {
        font-size: 20px;
        color: #5f2eff;
        margin-left: auto;
        width: 136px;
        height: 38px;
        border: 1px solid #5f2eff;
        border-radius: 19px;
        &:hover {
          background: linear-gradient(176deg, #406bff 0%, #5f2eff 100%);
          color: #ffffff;
        }
      }
    }
  }
}
</style>
